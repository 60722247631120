import { mixins } from 'vue-class-component';
import { EthereumProvider } from '@manifoldxyz/frontend-provider-core';
import OAuthMultiWalletMixin from '@/mixins/oAuthMultiWallet';

export default class OAuthMultiWalletMultiNetworkMixin extends mixins(OAuthMultiWalletMixin) {
  /**
   * Switches wallet to the default chain
   */
  async switchNetwork(): Promise<void> {
    await EthereumProvider.switchToChain(this.network[0]);
  }
}
