import { OAuthGrantType } from '@manifoldxyz/frontend-provider-types';

/**
 * @dev
 *
 * In Shopify, the entire app configuration is stored in our backend upon install
 * and retrieved via an API when `data-detect-app` is set on the connect-widget.
 *
 * The convention is that you can use connect-widget without any props IF
 * there is an "AppDetails" in our backend with the redirect URI of your website.
 */

const backendURL =
  'https://kygy4d4nc3.execute-api.us-east-1.amazonaws.com/prod/app/public?redirectURI=';

export interface AppDetails {
  clientId: string;
  app: string;
  redirectUri: string;
  grantType: OAuthGrantType;
  walletConnectProjectId?: string;
}

export const getDetails = async (): Promise<AppDetails> => {
  const siteURI = window.location.origin;
  const appDetails = await fetch(`${backendURL}${siteURI}`);
  if (appDetails.status === 200) return await appDetails.json();
  let errorMessage;
  try {
    const error = await appDetails.json();
    errorMessage = error.message || error.error;
  } catch (e) {
    // Could not decode error response
    console.error('Auto-detect error: ', e);
  }
  throw new Error(errorMessage || 'Service unavailable');
};
