import { inject, PropType, provide } from 'vue';
import { mixins, Options, Vue } from 'vue-class-component';
import { EthereumNetwork, OAuthGrantType } from '@manifoldxyz/frontend-provider-types';

export enum DelayAuth {
  false = 'false',
  true = 'true',
  always = 'always'
}

export interface IOptionsProps {
  [value: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    elementType?: any; // only used for Array types, specify type of array elements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: any;
    required?: boolean;
  };
}

/*
 * Adding a new prop? You'll want to declare in two places:
 *   1. MConnectOptionsProps with type and default
 *   2. MConnectProps (theres an obvious pattern)
 *
 * Optionally, enforce any new prop-dependency rules
 * in the created() hook of MConnectProvidePropsMixin.
 */
export const MConnectOptionsProps: IOptionsProps = {
  showBalance: {
    type: Boolean,
    default: true
  },
  alwaysOpen: {
    type: Boolean,
    default: false
  },
  connectWalletImage: {
    type: String,
    default: ''
  },
  showChain: {
    type: Boolean,
    default: true
  },
  avatar: {
    type: String,
    default: ''
  },
  multi: {
    type: Boolean,
    default: false
  },
  multiHidden: {
    type: String,
    default: ''
  },
  minimal: {
    type: Boolean,
    default: false
  },
  fallbackProvider: {
    type: Array as PropType<string[]>,
    elementType: String,
    default: [],
    required: false
  },
  network: {
    type: Array as PropType<EthereumNetwork[]>,
    elementType: Number,
    default: [],
    required: false
  },
  optionalNetwork: {
    type: Array as PropType<EthereumNetwork[]>,
    elementType: Number,
    default: [],
    required: false
  },
  walletConnectProjectId: {
    type: String,
    default: undefined,
    required: false
  },
  /**
   * @dev
   *
   * If `detectApp` is true, then `clientId`, `appName`, and `grantType` are
   * ignored. Instead, the `clientId`, `appName`, and `grantType` are
   * automatically detected from the current page's URL using our own API.
   * The OAuth experience is then forced on the user, if detected.
   *
   * This is exclusively used internally by our Shopify plugins and templates because
   * we automatically create a dev portal account and app for them.
   */
  detectApp: {
    type: Boolean,
    default: false
  },
  parentFrameUrl: {
    type: String,
    default: ''
  },
  autoReconnect: {
    type: Boolean,
    default: true
  },
  overrideConnectText: {
    type: String,
    default: ''
  },
  strictAuth: {
    type: Boolean,
    default: true
  },
  delayAuth: {
    type: String as PropType<DelayAuth>,
    default: DelayAuth.false
  },
  clientId: {
    type: String,
    default: ''
  },
  appName: {
    type: String,
    default: ''
  },
  grantType: {
    type: String as PropType<OAuthGrantType>,
    default: OAuthGrantType.SIGNATURE
  },
  message: {
    type: String,
    default: ''
  },
  browserProviderIgnoreDisconnect: {
    type: Boolean,
    default: false
  },
  enableNetworkSwitching: {
    type: Boolean,
    default: false
  }
};
export type MConnectOptionsPropsType = keyof typeof MConnectOptionsProps;

// Defined once and extended so that the inject() and provide() calls made by
// the two mixins in this file define all of the same things.
export class MConnectProps extends Vue {
  showBalance: boolean = MConnectOptionsProps.showBalance.default;
  connectWalletImage: string = MConnectOptionsProps.connectWalletImage.default;
  alwaysOpen: boolean = MConnectOptionsProps.alwaysOpen.default;
  showChain: boolean = MConnectOptionsProps.showChain.default;
  avatar: string = MConnectOptionsProps.avatar.default;
  multi: boolean = MConnectOptionsProps.multi.default;
  multiHidden: string = MConnectOptionsProps.multiHidden.default;
  minimal: boolean = MConnectOptionsProps.minimal.default;
  fallbackProvider: string[] = MConnectOptionsProps.fallbackProvider.default;
  network: EthereumNetwork[] = MConnectOptionsProps.network.default;
  optionalNetwork: EthereumNetwork[] = MConnectOptionsProps.optionalNetwork.default;
  walletConnectProjectId: string | undefined = MConnectOptionsProps.walletConnectProjectId.default;
  parentFrameUrl: string = MConnectOptionsProps.parentFrameUrl.default;
  autoReconnect: boolean = MConnectOptionsProps.autoReconnect.default;
  overrideConnectText: string = MConnectOptionsProps.overrideConnectText.default;
  strictAuth: boolean = MConnectOptionsProps.strictAuth.default;
  delayAuth: DelayAuth = MConnectOptionsProps.delayAuth.default;
  clientId: string = MConnectOptionsProps.clientId.default;
  appName: string = MConnectOptionsProps.appName.default;
  grantType: OAuthGrantType = MConnectOptionsProps.grantType.default;
  detectApp: boolean = MConnectOptionsProps.detectApp.default;
  message: string = MConnectOptionsProps.message.default;
  browserProviderIgnoreDisconnect: boolean =
    MConnectOptionsProps.browserProviderIgnoreDisconnect.default;
  enableNetworkSwitching: boolean = MConnectOptionsProps.enableNetworkSwitching.default;
}

// Used by WalletMixin to inject() back in all parent-provided props as variables
// Reference: https://vuejs.org/guide/components/provide-inject.html
export class MConnectInjectPropsMixin extends mixins(MConnectProps) {
  created(): void {
    // get all property definitions declared in MConnectProvidePropsMixin
    const keys = Object.keys(new MConnectProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      (this as any)[key] = inject(key); // eslint-disable-line
    }
  }
}

// Used by MConnect to provide() all its props to children components
// Reference: https://vuejs.org/guide/components/provide-inject.html
@Options({
  props: MConnectOptionsProps
})
export class MConnectProvidePropsMixin extends mixins(MConnectProps) {
  /*
   * Use this created hook to enforce any prop-dependency rules.
   */
  created(): void {
    this._validateProp();

    // define all properties for children who use the MConnectInjectPropsMixin
    const keys = Object.keys(new MConnectProps({}));
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      provide(key, (this as any)[key]); // eslint-disable-line
    }
  }

  _validateProp(): void {
    if ((this.multi || this.walletConnectProjectId) && this.minimal) {
      throw Error('Props multi/walletconnect-project-id and minimal are mutually exclusive');
    }

    if ((this.appName || this.clientId) && !(this.appName && this.clientId)) {
      throw Error('Props app-name and client-id must be provided together');
    }

    if (this.detectApp && (this.appName || this.clientId)) {
      throw Error('Props app-name and client-id cannot be provided if detect-app is true');
    }

    if (this.optionalNetwork.length && !this.network.length) {
      throw Error('Prop network must be provided if optionalNetwork is provided');
    }

    if (this.fallbackProvider.length) {
      const networks = [...this.network, ...this.optionalNetwork];
      if (!networks.length) {
        throw Error('Prop network must be provided if fallbackProvider is provided');
      }

      if (networks.length !== this.fallbackProvider.length) {
        throw Error(
          'Prop fallbackProvider must provide a provider for each specified network and optional network'
        );
      }
    }

    if (this.walletConnectProjectId && !this.network.length) {
      throw Error('Prop network must be provided if walletConnectProjectId is provided');
    }
  }
}
